/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
import ajax from 'src/commons/ajax';
import {getLoginUser, isLoginPage, formatMenus, getContainerId} from '@ra-lib/admin';
import {isNoAuthPage} from 'src/commons';
import {IS_SUB} from 'src/config';
import {toLogin} from '../commons/index';

/**
 * 处理菜单数据
 * @returns {<*{[]}|*>}
 */

// const menuData = [{id: 4, parentId: 1, title: '菜单管理', path: '/menus', order: 900, type: 1}]
const menuData = [];
const menu = (data) => {
    data.map((item) => {
        if (item.type === 1) {
            menuData.push({
                id: item.id,
                parentId: item.pid,
                title: item.name,
                order: 900,
                path: item.component === 'management' ? null : item.path,
                type: item.type,
                icon: item.icon,
                child: item?.child,
            });
        }
        if (item?.child?.length) {
            menu(item?.child);
        }
    });
    return menuData;
};

export default {
    /**
     * 获取菜单
     * @returns {Promise<*[]|*>}
     */
    async getMenuData() {
        // 非登录页面，不加载菜单
        if (isNoAuthPage()) return [];

        // 作为子应用，不加载
        if (IS_SUB) return [];

        // 获取服务端数据，并做缓存，防止多次调用接口
        return (this.getMenuData.__CACHE =
            this.getMenuData.__CACHE ||
            ajax
                .get('/api/v1/sys/permission/homeMenu')
                .then((res) => {
                    if (res?.code === 0) {
                        menu(res?.data);
                        localStorage.setItem('jurisdiction', JSON.stringify(menuData));
                        return menuData;
                    } else if (res?.code === 10000) {
                        // alert(res?.msg);
                        toLogin();
                    } else {
                        // alert(res?.msg);
                    }
                })
                .catch(() => []));
        

        // // 前端硬编码菜单
        // return [
        //     {id: 1, title: '系统管理', order: 900, type: 1, parentId: null},
        //     {id: 2, parentId: 1, title: '账号管理', path: '/sys/user', order: 900, type: 1},
        //     {id: 3, parentId: 1, title: '角色管理', path: '/sys/role', order: 900, type: 1},
        //     {id: 8, title: 'Home', path: '/', order: 900, type: 1},
        //     {id: 4, title: 'User info edit', path: '/userInfoEdit', order: 900, type: 1},
        //     {id: 5, title: 'Earnings management', path: '/earningsManagement', order: 900, type: 1},
        //     {id: 6, title: 'Affiliator management', path: '/affiliatorManagement', order: 900, type: 1},
        //     {id: 7, title: 'Payment management', path: '/paymentManagement', order: 900, type: 1},
        //     // {id: 4, parentId: 1, title: '菜单管理', path: '/menus', order: 900, type: 1},
        // ];
        // return null
    },
    /**
     * 获取系统菜单
     * @returns {Promise<T[]>}
     */
    async getMenus() {
        // mock时，做个延迟处理，否则菜单请求无法走mock
        if (process.env.REACT_APP_MOCK) await new Promise((resolve) => setTimeout(resolve));

        const serverMenus = await this.getMenuData();
        // const menus = serverMenus?.filter((item) => !item?.type || item?.type === 1)?.map((item) => {
        //         return {
        //             ...item,
        //             id: `${item?.id}`,
        //             parentId: `${item?.parentId}`,
        //         };
        //     });

        const menus = serverMenus
            ?.filter((item) => !item?.type || item?.type === 1)
            ?.map((item) => {
                return {
                    ...item,
                    id: `${item?.id}`,
                    parentId: `${item?.parentId}`,
                };
            });
        if (serverMenus?.length) {
            return formatMenus(menus);
        }
        return [];
    },
    /**
     * 获取用户收藏菜单
     * @returns {Promise<*>}
     */
    async getCollectedMenus() {
        // 登录页面，不加载
        if (isLoginPage()) return [];

        // 作为子应用，不加载
        if (IS_SUB) return [];

        const loginUser = getLoginUser();
        const data = await ajax.get('/authority/queryUserCollectedMenus', {userId: loginUser?.id});
        // const data = [];

        const menus = data.filter((item) => item.type === 1).map((item) => ({...item, isCollectedMenu: true}));

        return formatMenus(menus);
    },
    /**
     * 保存用户收藏菜单
     * @param menuId
     * @param collected
     * @returns {Promise<void>}
     */
    async saveCollectedMenu({menuId, collected}) {
        await ajax.post('/authority/addUserCollectMenu', {userId: getLoginUser()?.id, menuId, collected});
    },
    /**
     * 获取用户权限码
     * @returns {Promise<*[string]>}
     */
    async getPermissions() {
        const serverMenus = await this.getMenuData();
        return serverMenus.filter((item) => item.type === 2).map((item) => item.code);
    },
    /**
     * 获取子应用配置
     * @returns {Promise<*[{title, name, entry}]>}
     */
    async getSubApps() {
        // 从菜单数据中获取需要注册的乾坤子项目
        const menuTreeData = (await this.getMenus()) || [];

        // 传递给子应用的数据
        const loginUser = getLoginUser();
        const props = {
            mainApp: {
                loginUser: loginUser,
                token: loginUser?.token,
            },
        };
        let result = [];
        const loop = (nodes) =>
            nodes.forEach((node) => {
                const {_target, children} = node;
                if (_target === 'qiankun') {
                    const {title, name, entry} = node;
                    const container = `#${getContainerId(name)}`;
                    const activeRule = `/${name}`;

                    result.push({
                        title,
                        name,
                        entry,
                        container,
                        activeRule,
                        props,
                    });
                }
                if (children?.length) loop(children);
            });
        loop(menuTreeData);

        return result;
    },
};
