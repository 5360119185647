import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
// import { ConnectorNames } from '@pancakeswap/uikit'
import Web3 from 'web3'

const ConnectorNames = {
  Injected: "injected",
  WalletConnect: "walletconnect",
  BSC: "bsc"
}

const POLLING_INTERVAL = 12000
const rpcUrl = 'https://data-seed-prebsc-1-s2.binance.org:8545/'

// const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
const chainId = 97

const injected = new InjectedConnector({ supportedChainIds: [chainId] })

// const walletconnect = new WalletConnectConnector({
//   rpc: { [chainId]: rpcUrl },
//   bridge: 'https://bridge.walletconnect.org/',
//   qrcode: true,
//   // pollingInterval: POLLING_INTERVAL,
// })
const walletconnect = new WalletConnectConnector({
  rpc: { [chainId]: rpcUrl },
  bridge: 'https://bridge.walletconnect.org/',
  qrcode: true,
  // pollingInterval: POLLING_INTERVAL,
})

const bscConnector = new BscConnector({ supportedChainIds: [chainId] })
console.log(bscConnector)

export const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
}

export const getLibrary = (provider) => {
  return provider
}
